import React from "react"
import "../../../styles/course.scss"
import CourseNav from "../../../components/course/Nav"
import Footer from "../../../components/course/sections/Footer"
import SEO from "../../../components/seo"
import { graphql } from "gatsby"
import styled from "styled-components"

const SupportProgram = ({ data }: { data: { file: any } }) => {
  const { file } = data
  return (
    <div id={"course-container"}>
      <SEO
        title={"Wspieramy nastoletnie mamy | Szkoła Rodzenia z Anną Nowak-Dudą"}
        description={
          "Kompletna, profesjonalna szkoła rodzenia online. Bez stresu, dojazdów i problemów. Wsparcie także po porodzie."
        }
        image={file.childImageSharp.fluid.src}
        article={false}
      />
      <CourseNav />
      <section className="is-white" id="main">
        <div className="container is-widescreen">
          <div className="columns is-vcentered">
            <div className="column is-6-desktop is-offset-3-desktop">
              <div className="container">
                <h1 className="title is-3 has-text-font-comfortaa has-text-black-ter has-text-centered mb-4">
                  <strong className="has-text-primary">
                    Wspieramy nastoletnie mamy!
                  </strong>
                </h1>
                <Paragraph>
                  W Polsce każdego roku nastolatki rodzą ponad 9 tys. dzieci, a
                  ok. 700 nastoletnich ciężarnych ma mniej niż 15 lat.
                  Nastoletnie ciąże to wciąż temat tabu, który budzi wiele
                  kontrowersji.
                </Paragraph>
                <Paragraph>
                  Większość osób podchodzi do nastoletnich matek z
                  nieposzanowaniem, lekceważeniem, dezaprobatą, a nawet z
                  pogardą. Jednak czy granica wieku dyskwalifikuje młode kobiety
                  z bycia Mamą? <b>Jasne że nie!</b>
                </Paragraph>
                <Paragraph>
                  Zdajemy sobie sprawę, że tak, jak pierwsza wizyta u ginekologa
                  jest dla nieletnich mam ogromnym stresem i wiąże się z
                  uczuciem wstydu, tak uczestnictwo w zajęciach szkoły rodzenia
                  może wiązać się u Nich z uczuciem zażenowania. Wiemy, jak
                  ważne jest odpowiednie przygotowanie do porodu i połogu,
                  dlatego zdecydowałyśmy o programie bezpłatnego dostępu do
                  naszego Kursu Szkoły Rodzenia dla Nastoletnich Mam.
                </Paragraph>

                <h2>Zasady:</h2>
                <List>
                  <li>Jesteś w ciąży (lub do 3 miesięcy po porodzie)</li>
                  <li>Masz mniej niż 20 lat</li>
                </List>

                <h2>Zgłoszenia:</h2>
                <Paragraph>
                  <a href={"https://forms.gle/6LoDCG43LEYDU8WP8"}>
                    Formularz zgłoszeniowy do programu wsparcia dla nastoletnich
                    mam!
                  </a>
                </Paragraph>
                <Paragraph>
                  Po otrzymaniu Twojego zgłoszenia skontaktujemy się z Tobą w
                  celu weryfikacji podanych danych.
                </Paragraph>
                <Paragraph>
                  Po zakwalifikowaniu się do programu otrzymasz dostęp do
                  naszego kursu szkoły rodzenia (pakiet PREMIUM) - kompletnie
                  bezpłatnie.
                </Paragraph>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default SupportProgram

const Paragraph = styled.p`
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`

const List = styled.ul`
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
`

export const query = graphql`
  query {
    file(relativePath: { eq: "course-cover.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
